<template>
  <b-row>
    <b-col  >
      <b-row>
        <b-col>
          <b-pagination
              v-model="curPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
              limit="15"
          ></b-pagination>

        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
              :items="productList"
              :fields="fields"
              :per-page="perPage"
              :current-page="curPage"
              show-empty
              small
              headVariant="light"
              bordered
              responsive
              :busy="isLoading"
              tbody-class="acuvue-table"
              style="width: 99%"
              thead-class="acuvue-thead"
          >
            <template #table-busy>
              <div class="text-center text-secondary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Loading...</strong>
              </div>
            </template>
<!--            <template #thead-top="">-->
<!--              <b-tr>-->
<!--                <b-th colspan="5">Всего с прибылью меньше 50руб. {{reportLog.length}} артикулов</b-th>-->
<!--                <b-th></b-th>-->
<!--                <b-th colspan="4" class="text-center">Цена закупочная</b-th>-->
<!--                <b-th></b-th>-->
<!--                <b-th colspan="5" class="text-center">Цена на WB</b-th>-->
<!--                <b-th></b-th>-->
<!--              </b-tr>-->
<!--            </template>-->
            <template #empty="">
              <div role="alert" aria-live="polite"><div class="text-center my-2">Список пуст.</div></div>
            </template>

            <template #row-details="row">
              <product-table-v4-detail-view :items="row.item.detail" />
            </template>

            <template #cell(name)="row">
              {{row.item.name}}<br>
              <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                {{ row.detailsShowing ? 'свернуть' : 'развернуть'}}
              </b-button>
            </template>


            <template #cell(stats)="{item}">
              Всего карточек: {{item.detail.length}}, <br>
              В продаже: {{item.detail.filter(e=>e.wbData.qty>0).length}}, <br>
            <b-badge variant="danger" v-if="item.detail.filter(e=>(e.wbData.qty==0 && e.balances.m2+e.balances.m22+e.balances.msk+e.balances.msk_export+e.balances.spb+e.balances.spb_export>0)).length>0">Ошибка остатков! {{item.detail.filter(e=>(e.wbData.qty==0 && e.balances.m2+e.balances.m22+e.balances.msk+e.balances.msk_export+e.balances.spb+e.balances.spb_export>0)).length}}</b-badge>
            </template>
            <template #cell(prices)="{item}">
              <span title="Цена (Доход)">мин: {{Math.min(...item.prices)}} ({{calcDohod(Math.min(...item.prices), item.zakup, 17, 10)}}), </span>
              <span title="Цена (Доход)">макс: {{Math.max(...item.prices)}} ({{calcDohod(Math.max(...item.prices), item.zakup, 17, 10)}}) </span>

              <b-badge variant="danger" v-if="item.dohods.filter(e=>e<150).length" title="доход меньше 150">Слишком низкие цены! {{item.dohods.filter(e=>e<150).length}}</b-badge>
            </template>

            <template #cell(attrs)="{item}">
              {{item.attrs.slice(0,10)}}...
            </template>
          </b-table>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-pagination
              v-model="curPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
              limit="15"
          ></b-pagination>

        </b-col>
      </b-row>
    </b-col>

  </b-row>

</template>

<style>
.acuvue-thead th div {
  font-size: 12px;
}
</style>
<style scoped>
a {
  color: #111;
  font-weight: bold;
}
div {
  font-size: 12px;
}
</style>

<script>
import _ from "lodash"
import ProductTableV4DetailView from "@/ProductTableV4/components/TableV4detailView.vue";
import calcDohod from "@/ProductTableV4/helpers/calc";
// import dayjs from 'dayjs'
// import weekOfYear from "./node_modules/dayjs/plugin/weekOfYear.js"
// import './node_modules/dayjs/locale/ru.js'


export default {
  name: "product-table-v5",
  components: {ProductTableV4DetailView},
  data() {
    return {
      reportLog: [],
      fields: [
        { key: 'brand', label: 'Линзы', sortable: false, sortDirection: 'desc' },
        { key: 'cards', label: 'Карточки', sortable: true, sortDirection: 'desc' },
      ],
      isLoading: false,
      curPage: 1,
      totalRows: 100,
      perPage: 50,

    };
  },
  async mounted() {
    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});


  },
  methods: {


  },
  computed: {
    productList: function () {
      let result = this.reportLog
      console.log(result[0])

      return _.chain(result)
          .groupBy("name")
          .map((value, key) => ({
            name: key,
            wb_arts: value.map(e=>e.art_wb),
            prices: value.map(e=>e.wbData.price),
            dohods: value.map(e=>calcDohod(e.wbData.price, e.price, 17, 55)),
            attrs: value.map(e=>e.attr),
            zakup: value[0].price,
            detail: value
          }))
          .value()
    }
  },
};
</script>