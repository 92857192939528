<template>
  <b-row>
    <b-col >
<!--      <b-row>-->
<!--        <b-col md="auto">-->
<!--          <v-date-picker-->
<!--              v-model="range"-->
<!--              is-range-->
<!--              :masks="masks"-->
<!--              :columns="3"-->
<!--              locale="ru"-->
<!--              show-iso-weeknumbers-->
<!--              title-position="center"-->
<!--              mode="date"-->
<!--              :timezone="'UTC'"-->
<!--              is24hr-->
<!--          >-->
<!--          </v-date-picker>-->
<!--        </b-col>-->
<!--        <b-col>-->
<!--          Период: {{dateFormat(range)}}<br><br>-->
<!--          <a href="#" @click.prevent="filters.status='scanned'">Отсканированно:</a> <b>{{calcSumStats.scanned}} </b>({{calcSumStats.scannedErrors}} с ошибкой)<br>-->
<!--          <a href="#" @click.prevent="filters.status='addedPoints'">Начислено баллов:</a> <b>{{ calcSumStats.addedPoints || '?'}} </b>({{ calcSumStats.addedPointsQty }} покупателям) <br>-->
<!--          <a href="#" @click.prevent="filters.status='errors'">Статистика ошибок:</a> <br>-->
<!--          <div style="background-color: #e7e7e7;padding: 12px;width: 100%">-->
<!--            <div v-for="(se, indx) of calcSumStats.errorStats" :key="`stats-${indx}`">{{se.code}} - {{se.qty}}-->
<!--            </div>-->
<!--          </div>-->

<!--&lt;!&ndash;          <pre>USED_STICKER_ERROR - 3</pre>&ndash;&gt;-->
<!--&lt;!&ndash;          <pre>USER_NOT_FOUND_ERROR - 1</pre>&ndash;&gt;-->

<!--        </b-col>-->
<!--      </b-row> -->
      <b-row>
        <b-col >
          <div>
          <b-pagination
              v-model="curPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
              limit="15"
          ></b-pagination>
          </div>
        </b-col>
        <b-col >
            <b-button-group size="sm">
              <b-button variant="light" :key="`00-all`" @click.prevent="filters.seller=''" :class="{'active':filters.seller==''}">Все ({{items.length}})</b-button>
              <b-button variant="light" v-for="seller of sellers" :key="`11-${seller}`" :class="{'active':filters.seller==seller}" @click.prevent="filters.seller=seller">{{ seller }} ({{items.filter(e=>e.seller==seller).length}})</b-button>
            </b-button-group>
        </b-col>
        <b-col >
            <b-button-group size="sm">
              <b-button variant="light" :key="`000-all`" @click.prevent="filters.noQty=!filters.noQty;filters.lowPrice=false" :class="{'active':filters.noQty}">Ошибка остатков ({{items.filter(e=>(e.wbData.qty==0 && e.balances.m2+e.balances.m22+e.balances.msk+e.balances.msk_export+e.balances.spb+e.balances.spb_export>0)).length}})</b-button>
              <b-button variant="light" :key="`111-err`" @click.prevent="filters.lowPrice=!filters.lowPrice;filters.noQty=false" :class="{'active':filters.lowPrice}">Низкие цены ({{items.filter(e=>calcDohod(e.wbData.price, e.price, 14, 55)<150).length}})</b-button>
            </b-button-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
              :items="productList"
              :fields="fields"
              :per-page="perPage"
              :current-page="curPage"
              show-empty
              small
              headVariant="light"
              bordered
              responsive
              :busy="isLoading"
              tbody-class="acuvue-table"
              style="width: 99%"
              thead-class="acuvue-thead"
          >
            <template #table-busy>
              <div class="text-center text-secondary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Loading...</strong>
              </div>
            </template>
            <template #table-colgroup="scope">
              <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style=calcColWidth(field.key)
              >
            </template>
<!--            <template #thead-top="">-->
<!--              <b-tr>-->
<!--                <b-th colspan="5">Всего с прибылью меньше 50руб. {{reportLog.length}} артикулов</b-th>-->
<!--                <b-th></b-th>-->
<!--                <b-th colspan="4" class="text-center">Цена закупочная</b-th>-->
<!--                <b-th></b-th>-->
<!--                <b-th colspan="5" class="text-center">Цена на WB</b-th>-->
<!--                <b-th></b-th>-->
<!--              </b-tr>-->
<!--            </template>-->
            <template #empty="">
              <div role="alert" aria-live="polite"><div class="text-center my-2">Список пуст.</div></div>
            </template>


            <template #cell(attr)="{item}">
              <a :href="'https://www.wildberries.ru/catalog/' + item.art_wb + '/detail.aspx?targetUrl=XS'" target="_blank">{{item.seller}} {{item.attr}}</a>

            </template>
            <template #cell(qty)="{item}">
              <span title="Наш склад + Склад поставщика">спб: {{item.balances.spb+item.balances.m2+item.balances.m22}} | {{item.balances.spb_export}}</span>,
              <span title="Склад в москве + Склад поставщика в москве">мск: {{item.balances.msk}} | {{item.balances.msk_export}}</span>,
              <span title="Транслируем на вб">wb: {{item.wbData.qty}}</span>
            </template>
<!--            <template #cell(qtyOnWb)="{item}">-->
<!--              <span title="FBO + FBS">спб: {{item.wbData}} | {{item.balances.spb_export}}</span>,-->
<!--&lt;!&ndash;              <span title="Склад в москве + Склад поставщика в москве">мск: {{item.balances.msk}} | {{item.balances.msk_export}}</span>&ndash;&gt;-->
<!--            </template>-->
            <template #cell(salePrice)="{item}">
              <product-table-v4-price-widget :sale-price="item.wbData.price" :pr-wb="14" :upak="15" :zakup="item.price" :key="item.art_wb" />
            </template>
            <template #cell(priceDiscount)="{item}">
              <b-row>
                <b-col lg="4">{{item.wbData.price*0.45}}</b-col>
                <b-col lg="6" class="pr-0"> <b-badge href="#" size="sm" variant="primary" class="mr-2">применить</b-badge> </b-col>

              </b-row>
             </template>
            <template #cell(priceIdentical)="{item}">
              {{item.wbData.price*0.45}}
            </template>
<!--            <template #cell(prices)="{item}">-->
<!--              {{item.prices.slice(0,10)}}...-->
<!--            </template>-->
<!--            <template #cell(attrs)="{item}">-->
<!--              {{item.attrs.slice(0,10)}}...-->
<!--            </template>-->
          </b-table>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-pagination
              v-model="curPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
              limit="15"
          ></b-pagination>

        </b-col>
      </b-row>
    </b-col>

  </b-row>

</template>

<style>
.acuvue-thead th div {
  font-size: 12px;
}
</style>
<style scoped>
a {
  color: #111;
  font-weight: bold;
}
a.badge {
   color: #fff;
   font-weight: bold;
 }
div {
  font-size: 12px;
}
</style>

<script>
// import _ from "lodash"
// import dayjs from 'dayjs'
// import weekOfYear from "./node_modules/dayjs/plugin/weekOfYear.js"
// import './node_modules/dayjs/locale/ru.js'
import calcDohod from "@/ProductTableV4/helpers/calc";
import ProductTableV4PriceWidget from "@/ProductTableV4/components/PriceDohodKomsaWidget.vue";

export default {
  name: "product-table-v4-detail-view",
  components: {ProductTableV4PriceWidget},
  props: ['items'],
  data() {
    return {
      reportLog: [],
      fields: [
        { key: 'attr', label: 'Линзы', sortable: false, sortDirection: 'desc' },
        { key: 'qty', label: 'Остатки', sortable: false, sortDirection: 'desc' },
        { key: 'salePrice', label: 'Цена на сайте', sortable: true, sortDirection: 'desc' },
        { key: 'priceDiscount', label: 'Цена для акции', sortable: true, sortDirection: 'desc' },
        { key: 'priceIdentical', label: 'Цены конкурентов', sortable: true, sortDirection: 'desc' },
      ],
      isLoading: false,
      curPage: 1,
      totalRows: 100,
      perPage: 50,
      filters: {
        seller: '',
        noQty: false,
        lowPrice: false
      },
      sellers: []

    };
  },
  async mounted() {
    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});

    this.calcDohod = calcDohod
    this.totalRows = this.items.length
    this.sellers=[...new Set(this.items.map(e=>e.seller))]
  },
  methods: {
    calcColWidth(key) {
      if (key === 'attr') return "width:300px"
      if (key === 'salePrice') return "width:300px"
      if (key === 'art_wb') return "width:300px"
      if (key === 'qty') return "width:180px"
      if (key === 'priceDiscount') return "width:120px"
      if (key === 'priceIdentical') return "width:120px"
      // if (key === 'actions') return "width:40px"
      // return "{ width: field.key === 'sales' ? '120px' : '180px' }"
      // return "width:80px"
    },

  },
  computed: {
    productList: function () {
      let result = this.items.map(e=>({...e, dohod: calcDohod(e.wbData.price, e.price, 14, 55)}))

      if (this.filters.seller) result = result.filter(e=>e.seller==this.filters.seller)
      if (this.filters.noQty) result = result.filter(e=>(e.wbData.qty==0 && e.balances.m2+e.balances.m22+e.balances.msk+e.balances.msk_export+e.balances.spb+e.balances.spb_export>0))
      if (this.filters.lowPrice) result = result.filter(e=>e.dohod<150)

      return result

      // return _.chain(result)
      //     .groupBy("name")
      //     .map((value, key) => ({
      //       name: key,
      //       wb_arts: value.map(e=>e.art_wb),
      //       prices: value.map(e=>e.wbData.price),
      //       attrs: value.map(e=>e.attr),
      //     }))
      //     .value()
    }
  },
  watch: {
    productList: function () {
      console.log('reload')
      this.totalRows = this.productList.length
    },
  }
};
</script>