<template>
  <b-row>
        <b-col sm="4"   class="pr-0"> <b-form-input type="number" id="input-small" size="sm" v-model="sp"></b-form-input></b-col>
        <b-col sm="3" class="pr-0"><b-form-input type="number" disabled id="input-small" size="sm" v-model="dohod"></b-form-input></b-col>
        <b-col sm="2" class="pr-0"> <b-form-input type="number" id="input-small" size="sm" v-model="komsawb"></b-form-input></b-col>
    <b-col class="pr-0"> <b-badge href="#" size="sm" variant="primary" class="mr-2">применить</b-badge> </b-col>
  </b-row>

</template>

<style>

</style>
<style scoped>
a {
  color: #111;
  font-weight: bold;
}
a.badge {
  color: #fff;
  font-weight: bold;
}
div {
  font-size: 12px;
}

</style>
<style>
input[type="number"] {
  padding: 2px;
  height: 20px;
  display: block;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef!important;
  opacity: 1;
}
</style>

<script>
// import _ from "lodash"
// import dayjs from 'dayjs'
// import weekOfYear from "./node_modules/dayjs/plugin/weekOfYear.js"
// import './node_modules/dayjs/locale/ru.js'

export default {
  name: "product-table-v4-price-widget",
  props: ['salePrice','zakup','prWb','upak'],
  data() {
    return {
      isLoading: false,
      sp: 0,
      komsawb: 0,

    };
  },
  async mounted() {
    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});

    this.sp=this.salePrice
    this.komsawb=this.prWb
  },
  methods: {

  },
  computed: {
    dohod: function () {
      return Math.ceil(this.sp - this.zakup - this.sp/100*this.komsawb - this.upak)
    }
  },
};
</script>